<template>
    <div class="mx-0 px-0 py-0 mt-3">
        <v-row no-gutters class="mx-4">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredData"
                    class="elevation-2 mt-6"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    single-expand
                    :expanded.sync="expanded"
                    item-key="id"
                    show-expand
                    fixed-header
                    style="background-color: #eeeeee"
                    :style="{ 'background-color': '#eeeeee' }"
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="pa-0 ma-0"
                            :style="{ 'background-color': '#eeeeee' }"
                        >
                            <v-col cols="12" class="d-flex">
                                <p
                                    class="text-h5 my-0 pl-3 font-weight-bold mr-4 mt-1"
                                >
                                    PAYROLL
                                </p>
                                <v-btn-toggle
                                    mandatory
                                    borderless
                                    style="height: 36px;"
                                    class="mr-4 my-0"
                                >
                                    <v-btn
                                        @click="filter = 'weekly'"
                                        style="font-size: 12px; height: 36px;"
                                        class="px-xs-1 px-sm-1 px-md-3 px-lg-3 px-xl-3 py-1"
                                    >
                                        Weekly
                                    </v-btn>
                                    <v-btn
                                        @click="filter = 'monthly'"
                                        style="font-size: 12px; height: 36px;"
                                        class="px-xs-1 px-sm-1 px-md-3 px-lg-3 px-xl-3 py-1"
                                    >
                                        Monthly
                                    </v-btn>
                                </v-btn-toggle>
                                <v-spacer />
                                <v-select
                                    v-if="filter == 'weekly'"
                                    class="mt-4 mr-4"
                                    dense
                                    v-model="weekFilter"
                                    :items="availableWeeks"
                                    label="Select Week"
                                    style="width: 150px; max-width: 150px;"
                                    :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                    }"
                                ></v-select>
                                <v-select
                                    v-else
                                    class="mt-4 mr-4"
                                    dense
                                    v-model="monthFilter"
                                    :items="availableMonths"
                                    label="Select Month"
                                    style="width: 150px; max-width: 150px;"
                                    :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                    }"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.totalHours`]="{ item }">
                        <div class="d-flex justify-start align-end">
                            <p class="my-0">
                                {{
                                    (hours =>
                                        hours != 0.0 ? `${hours} h` : '-')(
                                        getTotalHours(item)
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                        <div class="d-flex justify-start align-end">
                            <p class="my-0">
                                {{
                                    (price =>
                                        price !== '-' ? `$ ${price}` : price)(
                                        setHourlyPrice(item)
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.otherCosts`]="{ item }">
                        <div class="d-flex justify-start align-end">
                            <p class="my-0">
                                {{
                                    (otherCosts =>
                                        otherCosts !== '-'
                                            ? `$ ${otherCosts}`
                                            : otherCosts)(setOtherCosts(item))
                                }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.total`]="{ item }">
                        <div class="d-flex justify-start align-end">
                            <p class="my-0">
                                {{
                                    (total =>
                                        total !== '-' ? `$ ${total}` : total)(
                                        setTotal(item)
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div>
                            <v-tooltip
                                v-if="getStatus(item) == 'Pending'"
                                bottom
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="openAlertPayroll(item)"
                                    >
                                        <v-icon>mdi-timer-stop-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Close Payroll</span>
                            </v-tooltip>
                            <v-tooltip
                                v-if="getStatus(item) == 'Payroll'"
                                bottom
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="openReportDialog(item)"
                                    >
                                        <v-icon
                                            >mdi-clipboard-edit-outline</v-icon
                                        >
                                    </v-btn>
                                </template>
                                <span>Generate Report</span>
                            </v-tooltip>
                            <v-tooltip
                                v-if="getStatus(item) == 'Processed'"
                                bottom
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="openReport(item)"
                                    >
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>View Report</span>
                            </v-tooltip>
                            <v-tooltip v-if="getStatus(item) == 'Paid'" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="openDeleteRecordDialog(item)"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar reporte</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template
                        v-slot:expanded-item="{ headers, item: timesheet }"
                    >
                        <td
                            :colspan="headers.length"
                            class="py-4 ma-0 pl-10 pr-10 outlined"
                            :style="{ background: 'white' }"
                        >
                            <div
                                class="mb-4"
                                v-if="
                                    timesheet.lastExternalUsersData.length > 0
                                "
                            >
                                <v-data-table
                                    :headers="getTimesheetHeaders(timesheet)"
                                    :items="timesheet.lastExternalUsersData"
                                    class="ma-0 pa-0 elevation-0"
                                    :mobile-breakpoint="0"
                                    :loading="timesheetLoading"
                                    :style="{ width: 'auto' }"
                                    fixed-header
                                    hide-default-footer
                                >
                                    <template v-slot:top>
                                        <v-row
                                            no-gutter
                                            class="pa-0 ma-0"
                                            :style="{
                                                'background-color': '#eeeeee',
                                            }"
                                        >
                                            <v-col cols="12" class="d-flex">
                                                <p
                                                    class="text-h5 my-0 pl-3 font-weight-bold mr-4 mt-1"
                                                >
                                                    {{
                                                        filter == 'weekly'
                                                            ? `Last Week`
                                                            : `Last Month`
                                                    }}
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template v-slot:[`item.date`]="{ item }">
                                        <div
                                            class="d-flex justify-start align-end"
                                        >
                                            <p class="my-0">
                                                {{
                                                    filter == 'weekly'
                                                        ? item.date
                                                            ? getDayOfWeek(
                                                                  item.date
                                                              )
                                                            : ''
                                                        : item.date
                                                        ? getDayOfWeek(
                                                              item.date
                                                          ) +
                                                          '/' +
                                                          item.date.split(
                                                              '-'
                                                          )[2]
                                                        : ''
                                                }}
                                            </p>
                                        </div>
                                    </template>

                                    <template v-slot:[`item.scope`]="{ item }">
                                        <div class="d-flex justify-center">
                                            <p class="my-0">
                                                {{
                                                    item.selectedCost?.cost
                                                        .reference || ''
                                                }}
                                            </p>
                                        </div>
                                    </template>
                                    <template
                                        v-slot:[`item.location`]="{ item }"
                                    >
                                        <div class="d-flex justify-center">
                                            <p class="my-0">
                                                {{ item.projectLocation.name }}
                                            </p>
                                        </div>
                                    </template>
                                    <template
                                        v-slot:[`item.clockIn`]="{ item }"
                                    >
                                        <div class="d-flex justify-center">
                                            <p class="my-0">
                                                {{
                                                    item.clockIn
                                                        ? formatTime(
                                                              item.clockIn
                                                          )
                                                        : ''
                                                }}
                                            </p>
                                        </div>
                                    </template>
                                    <template
                                        v-slot:[`item.clockOut`]="{ item }"
                                    >
                                        <div class="d-flex justify-center">
                                            <p class="my-0">
                                                {{
                                                    item.clockOut
                                                        ? formatTime(
                                                              item.clockOut
                                                          )
                                                        : ''
                                                }}
                                            </p>
                                        </div>
                                    </template>
                                    <template
                                        v-slot:[`item.totalTime`]="{ item }"
                                    >
                                        <div class="d-flex justify-center">
                                            <p class="my-0">
                                                {{
                                                    item.clockIn &&
                                                    item.clockOut
                                                        ? (
                                                              (item.clockOut
                                                                  ._seconds -
                                                                  item.clockIn
                                                                      ._seconds) /
                                                              3600
                                                          ).toFixed(2) +
                                                          ' hours'
                                                        : ''
                                                }}
                                            </p>
                                        </div>
                                    </template>
                                    <template v-slot:[`item.status`]="{ item }">
                                        <div class="d-flex justify-center">
                                            <p class="my-0">
                                                {{ getStatusTimeSheets(item) }}
                                            </p>
                                        </div>
                                    </template>
                                    <template
                                        v-slot:[`item.actions`]="{ item }"
                                    >
                                        <div>
                                            <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-btn
                                                        small
                                                        icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :disabled="
                                                            getStatusTimeSheets(
                                                                item
                                                            ) === 'Closed'
                                                        "
                                                        @click="
                                                            openEditRecordDialog(
                                                                item
                                                            )
                                                        "
                                                    >
                                                        <v-icon
                                                            >mdi-pencil</v-icon
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Edit</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-btn
                                                        small
                                                        icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :disabled="
                                                            getStatusTimeSheets(
                                                                item
                                                            ) === 'Closed'
                                                        "
                                                        @click="
                                                            openDeleteRecordDialog(
                                                                item
                                                            )
                                                        "
                                                    >
                                                        <v-icon
                                                            >mdi-delete</v-icon
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Delete</span>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                </v-data-table>
                            </div>
                            <div class="mb-4">
                                <v-data-table
                                    :headers="getTimesheetHeaders(timesheet)"
                                    :items="timesheet.externalUsersData"
                                    class="ma-0 pa-0 elevation-0"
                                    :mobile-breakpoint="0"
                                    :loading="timesheetLoading"
                                    :style="{ width: 'auto' }"
                                    fixed-header
                                    hide-default-footer
                                >
                                    <template v-slot:top>
                                        <v-row
                                            no-gutter
                                            class="pa-0 ma-0"
                                            :style="{
                                                'background-color': '#eeeeee',
                                            }"
                                        >
                                            <v-col cols="12" class="d-flex">
                                                <p
                                                    class="text-h5 my-0 pl-3 font-weight-bold mr-4 mt-1"
                                                >
                                                    {{
                                                        filter == 'weekly'
                                                            ? `Week ${weekFilter} Report`
                                                            : `${monthFilter} Report`
                                                    }}
                                                </p>
                                                <v-spacer />
                                                <v-tooltip
                                                    bottom
                                                    v-if="
                                                        userType ==
                                                            'superintendent' &&
                                                            getStatus(
                                                                timesheet
                                                            ) == 'Pending'
                                                    "
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-btn
                                                            small
                                                            icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="
                                                                createTimesheet(
                                                                    timesheet
                                                                )
                                                            "
                                                        >
                                                            <v-icon
                                                                >mdi-plus</v-icon
                                                            >
                                                        </v-btn>
                                                    </template>
                                                    <span
                                                        >Create Timesheet</span
                                                    >
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template v-slot:[`item.date`]="{ item }">
                                        <div
                                            class="d-flex justify-start align-end"
                                        >
                                            <p class="my-0">
                                                {{
                                                    filter == 'weekly'
                                                        ? item.date
                                                            ? getDayOfWeek(
                                                                  item.date
                                                              )
                                                            : ''
                                                        : item.date
                                                        ? getDayOfWeek(
                                                              item.date
                                                          ) +
                                                          '/' +
                                                          item.date.split(
                                                              '-'
                                                          )[2]
                                                        : ''
                                                }}
                                            </p>
                                        </div>
                                    </template>

                                    <template v-slot:[`item.scope`]="{ item }">
                                        <div class="d-flex justify-center">
                                            <p class="my-0">
                                                {{
                                                    item.selectedCost?.cost
                                                        .reference || ''
                                                }}
                                            </p>
                                        </div>
                                    </template>
                                    <template
                                        v-slot:[`item.location`]="{ item }"
                                    >
                                        <div class="d-flex justify-center">
                                            <p class="my-0">
                                                {{ item.projectLocation.name }}
                                            </p>
                                        </div>
                                    </template>
                                    <template
                                        v-slot:[`item.clockIn`]="{ item }"
                                    >
                                        <div class="d-flex justify-center">
                                            <p class="my-0">
                                                {{
                                                    item.clockIn
                                                        ? formatTime(
                                                              item.clockIn
                                                          )
                                                        : ''
                                                }}
                                            </p>
                                        </div>
                                    </template>
                                    <template
                                        v-slot:[`item.clockOut`]="{ item }"
                                    >
                                        <div class="d-flex justify-center">
                                            <p class="my-0">
                                                {{
                                                    item.clockOut
                                                        ? formatTime(
                                                              item.clockOut
                                                          )
                                                        : ''
                                                }}
                                            </p>
                                        </div>
                                    </template>
                                    <template
                                        v-slot:[`item.totalTime`]="{ item }"
                                    >
                                        <div class="d-flex justify-center">
                                            <p class="my-0">
                                                {{
                                                    item.clockIn &&
                                                    item.clockOut
                                                        ? (
                                                              (item.clockOut
                                                                  ._seconds -
                                                                  item.clockIn
                                                                      ._seconds) /
                                                              3600
                                                          ).toFixed(2) +
                                                          ' hours'
                                                        : ''
                                                }}
                                            </p>
                                        </div>
                                    </template>
                                    <template v-slot:[`item.status`]="{ item }">
                                        <div class="d-flex justify-center">
                                            <p class="my-0">
                                                {{ getStatusTimeSheets(item) }}
                                            </p>
                                        </div>
                                    </template>
                                    <template
                                        v-slot:[`item.actions`]="{ item }"
                                    >
                                        <div>
                                            <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-btn
                                                        small
                                                        icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :disabled="
                                                            getStatusTimeSheets(
                                                                item
                                                            ) === 'Closed'
                                                        "
                                                        @click="
                                                            openEditRecordDialog(
                                                                item
                                                            )
                                                        "
                                                    >
                                                        <v-icon
                                                            >mdi-pencil</v-icon
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Edit</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-btn
                                                        small
                                                        icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :disabled="
                                                            getStatusTimeSheets(
                                                                item
                                                            ) === 'Closed'
                                                        "
                                                        @click="
                                                            openDeleteRecordDialog(
                                                                item
                                                            )
                                                        "
                                                    >
                                                        <v-icon
                                                            >mdi-delete</v-icon
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Delete</span>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                </v-data-table>
                            </div>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <!-- dialog delete record -->
        <v-dialog
            :retain-focus="false"
            v-model="dialogDeleteRecord"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5">Delete Record</v-card-title>
                <v-card-text>
                    Do you want to delete the record?
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="closeDialogDeleteRecord()"
                    >
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteRecord()"
                        text
                        color="error"
                        :loading="loading2"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog edit record -->
        <v-dialog
            persistent
            max-width="470px"
            v-model="dialogEditRecord"
            content-class="custom-dialog"
        >
            <v-card :loading="loading2" class="custom-dialog">
                <v-card-title class="text-h5">
                    <v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="closeDialogEditRecord"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ 'Edit Record' }}
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-divider class="mx-5" />
                <v-card-text>
                    <v-form
                        v-model="valid"
                        class="mx-5 mt-3"
                        :disabled="loading2"
                    >
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="recordToEdit.date"
                                    type="text"
                                    label="Date"
                                    prepend-icon="mdi-calendar-month"
                                    disabled
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0 mb-3">
                            <v-col cols="12" class="py-0">
                                <v-select
                                    v-model="scopeSelected"
                                    :items="keys"
                                    label="Select a Scope"
                                    hide-details
                                    prepend-icon="mdi-receipt-text-outline"
                                    hide-selected
                                    @change="updateValue"
                                    :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                    }"
                                />
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <template>
                                    <v-row class="mt-2 mb-1">
                                        <v-col cols="auto">
                                            <v-icon>mdi-clock-start</v-icon>
                                        </v-col>
                                        <v-col>
                                            <vue-timepicker
                                                v-model="clockInSelected"
                                                class="custom-timepicker"
                                                format="hh:mm:ss a"
                                                input-width="100%"
                                                close-on-complete
                                                placeholder="Clock In"
                                                hide-clear-button
                                                :disabled="loading2"
                                            ></vue-timepicker>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <template>
                                    <v-row class="mt-2 mb-1">
                                        <v-col cols="auto">
                                            <v-icon>mdi-clock-end</v-icon>
                                        </v-col>
                                        <v-col>
                                            <vue-timepicker
                                                v-model="clockOutSelected"
                                                class="custom-timepicker"
                                                format="hh:mm:ss a"
                                                input-width="100%"
                                                close-on-complete
                                                placeholder="Clock Out"
                                                hide-clear-button
                                                :disabled="loading2"
                                            ></vue-timepicker>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="selectedNotes"
                                    type="text"
                                    label="Notes"
                                    prepend-icon="mdi-receipt-text-outline"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        :loading="loading2"
                        @click="editRecord"
                        :disabled="isInvalidTime"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog report -->
        <v-dialog
            persistent
            width="750px"
            max-width="800px"
            v-model="dialogReport"
            content-class="custom-dialog"
        >
            <v-card :loading="loading2" class="custom-dialog">
                <v-card-title class="text-h5">
                    <v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="closeDialogReport"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{
                        filter == 'weekly'
                            ? `Create Report: Week ${weekFilter}`
                            : `Create Report: ${monthFilter}`
                    }}
                    <v-spacer />
                    <v-btn
                        :loading="loading2"
                        color="primary"
                        text
                        @click="saveReport()"
                    >
                        SAVE
                    </v-btn>
                </v-card-title>
                <v-divider class="mx-5" />
                <v-card-text>
                    <v-col cols="12" class="mt-4">
                        <h2>DETAILS</h2>
                        <v-divider class="mt-2" />
                        <v-form
                            v-model="valid2"
                            class="mx-5 mt-3"
                            :disabled="loading2"
                        >
                            <v-row class="mt-0">
                                <v-col cols="6" class="py-0">
                                    <v-text-field
                                        v-model="reportData.name"
                                        type="text"
                                        label="Name"
                                        prepend-icon="mdi-account"
                                        readonly
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-text-field
                                        v-model="reportData.role"
                                        type="text"
                                        label="Role"
                                        prepend-icon="mdi-card-account-details-outline"
                                        readonly
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col cols="6" class="py-0">
                                    <v-text-field
                                        v-model="reportData.email"
                                        type="text"
                                        label="email"
                                        prepend-icon="mdi-email-outline"
                                        readonly
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-text-field
                                        v-model="reportData.phone"
                                        type="text"
                                        label="Phone"
                                        prepend-icon="mdi-phone-outline"
                                        readonly
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col cols="12" class="py-0">
                                    <v-text-field
                                        v-model="reportData.projectName"
                                        type="text"
                                        label="Project"
                                        prepend-icon="mdi-text-box-outline"
                                        readonly
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col cols="6" class="py-0">
                                    <v-text-field
                                        v-model="reportData.totalHours"
                                        type="text"
                                        label="Reported Hours"
                                        prepend-icon="mdi-clock-check-outline"
                                        readonly
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-text-field
                                        v-model="reportData.hourlyPrice"
                                        type="number"
                                        :rules="[rules.validateNumber]"
                                        label="Hourly Price"
                                        prepend-icon="mdi-currency-usd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col cols="12" class="py-0">
                                    <v-text-field
                                        v-model="reportData.otherCosts"
                                        type="number"
                                        label="Other Costs"
                                        :rules="[rules.validateNumber]"
                                        prepend-icon="mdi-currency-usd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col cols="12" class="py-0">
                                    <v-text-field
                                        v-model="getTotalCosts"
                                        type="number"
                                        label="Total"
                                        prepend-icon="mdi-currency-usd"
                                        disabled
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col cols="12" class="py-0">
                                    <v-text-field
                                        v-model="reportData.reportNotes"
                                        type="text"
                                        label="Report Notes"
                                        prepend-icon="mdi-receipt-text-outline"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                    <v-col cols="12" class="mt-4">
                        <h2>ATTACHMENTS</h2>
                        <v-divider class="mt-2" />
                        <div class="d-flex align-center flex-wrap mt-4">
                            <div
                                v-for="(file, i) in reportData.attachments"
                                :key="i"
                                class="d-flex"
                            >
                                <v-card
                                    class="d-flex ma-0 ml-2 pa-1"
                                    @click="openFileAttached(file.name)"
                                    :style="{ position: 'relative' }"
                                >
                                    <v-img
                                        class="ma-0 pa-0"
                                        max-width="100px"
                                        max-height="100px"
                                        :src="
                                            file.pdfPreview
                                                ? file.pdfPreview
                                                : file.url
                                        "
                                    >
                                    </v-img>
                                    <div>
                                        <v-btn
                                            small
                                            icon
                                            color="red"
                                            elevation="4"
                                            :style="{
                                                position: 'absolute',
                                                top: '8px',
                                                right: '8px',
                                            }"
                                        >
                                            <v-icon
                                                background
                                                @click.stop="
                                                    deleteFile(file.name)
                                                "
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-card>
                            </div>
                            <v-btn
                                @click="onButtonClick"
                                depressed
                                fab
                                x-small
                                color="primary"
                                class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <input
                                ref="uploader2"
                                class="d-none"
                                type="file"
                                accept="application/pdf, image/*"
                                @change="onFileChanged"
                            />
                        </div>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        :loading="loading2"
                        :disabled="!validForm"
                        @click="saveReport(true)"
                    >
                        GENERATE REPORT
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog close payroll -->
        <v-dialog
            :retain-focus="false"
            v-model="closePayrollDialog"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5">Close Payroll</v-card-title>
                <v-card-text>
                    Do you want to close the payroll at this moment?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDialogPayroll()">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closePayroll()"
                        text
                        color="error"
                        :loading="loading2"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog create timesheet -->
        <v-dialog
            persistent
            max-width="470px"
            v-model="dialogCreateTimesheet"
            content-class="custom-dialog"
        >
            <v-card :loading="loading2" class="custom-dialog">
                <v-card-title class="text-h5">
                    <v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="closeCreateTimesheet"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ 'Create Timesheet' }}
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-divider class="mx-5" />
                <v-card-text>
                    <v-form
                        v-model="valid3"
                        class="mx-5 mt-3"
                        :disabled="loading2"
                    >
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="timesheetDate"
                                            label="Date *"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            class="pt-1 mt-4"
                                            required
                                            :rules="[rules.required]"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="selectedDateTimesheet"
                                        :allowed-dates="allowedDates"
                                        @input="updateDate"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0 mb-3">
                            <v-col cols="12" class="py-0">
                                <v-select
                                    v-model="scopeTimesheet"
                                    :items="scopes"
                                    label="Select a Scope *"
                                    hide-details
                                    prepend-icon="mdi-receipt-text-outline"
                                    hide-selected
                                    :rules="[rules.required]"
                                    @change="updateScope"
                                    :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                    }"
                                />
                            </v-col>
                        </v-row>
                        <v-row class="mt-0 mb-3">
                            <v-col cols="12" class="py-0">
                                <v-select
                                    v-model="projectLocation"
                                    :items="projectLocations"
                                    label="Project Location *"
                                    hide-details
                                    prepend-icon="mdi-map-marker"
                                    item-text="name"
                                    :rules="[rules.required]"
                                    :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                    }"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <template>
                                    <v-row class="mt-2 mb-1">
                                        <v-col cols="auto">
                                            <v-icon>mdi-clock-start</v-icon>
                                        </v-col>
                                        <v-col>
                                            <vue-timepicker
                                                v-model="clockInTimesheet"
                                                class="custom-timepicker"
                                                format="hh:mm:ss a"
                                                input-width="100%"
                                                close-on-complete
                                                placeholder="Clock In *"
                                                hide-clear-button
                                                :disabled="loading2"
                                            ></vue-timepicker>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <template>
                                    <v-row class="mt-2 mb-1">
                                        <v-col cols="auto">
                                            <v-icon>mdi-clock-end</v-icon>
                                        </v-col>
                                        <v-col>
                                            <vue-timepicker
                                                v-model="clockOutTimesheet"
                                                class="custom-timepicker"
                                                format="hh:mm:ss a"
                                                input-width="100%"
                                                close-on-complete
                                                placeholder="Clock Out *"
                                                hide-clear-button
                                                :disabled="loading2"
                                            ></vue-timepicker>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="timesheetNotes"
                                    type="text"
                                    label="Notes"
                                    prepend-icon="mdi-receipt-text-outline"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        :loading="loading2"
                        @click="saveTimesheet"
                        :disabled="isInvalidTimesheet || !valid3"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import API from '@/services/api'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { objDiff } from '@/helpers/objDiff.js'
import { storage } from '@/services/firebase'
import { deleteFile } from '@/services/storage/deleteFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import {
    loadPDFPreview,
    generateHTMLReport,
} from '@/components/Nomina/ReportNomina.js'
import _ from 'lodash'
import { mapMutations } from 'vuex'
import moment from 'moment'
import { dataURLtoFile } from '@/helpers/base64ToFile.js'
export default {
    name: 'nomina',
    components: { VueTimepicker },
    data: () => ({
        headers: [
            {
                text: 'Name',
                value: 'name',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Identification',
                value: 'identification',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Project',
                value: 'projectName',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Total Hours',
                value: 'totalHours',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Price',
                value: 'price',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Other Costs',
                value: 'otherCosts',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Total',
                value: 'total',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Notes',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Actions',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        dialogReport: false,
        dialogCreateTimesheet: false,
        selectedDateTimesheet: null,
        timesheetDate: null,
        valid3: false,
        scopeTimesheet: null,
        scopeValueTimesheet: null,
        itemsScopesTimesheet: {},
        selectedTimesheet: {},
        valid2: false,
        menu: false,
        reportUser: {},
        reportData: {
            totalHours: null,
            reportNotes: '',
            otherCosts: '',
            hourlyPrice: '',
            name: null,
            role: null,
            email: null,
            phone: null,
            projectName: null,
            externalUsersData: [],
            date: '',
            attachments: [],
        },
        originalReport: {},
        companyId: JSON.parse(localStorage.getItem('company')),
        rules: {
            required: v => !!v || 'Required',
            validateNumber: v => {
                const numberValue = parseFloat(v)
                return (
                    (!isNaN(numberValue) && numberValue > 0) || 'Invalid Number'
                )
            },
        },
        localAttachments: [],
        scopeSelected: null,
        scopeValue: null,
        itemsScopeSelected: {},
        clockInTimesheet: { hh: '', mm: '', ss: '', a: '' },
        clockOutTimesheet: { hh: '', mm: '', ss: '', a: '' },
        clockInSelected: { hh: '', mm: '', ss: '', a: '' },
        clockOutSelected: { hh: '', mm: '', ss: '', a: '' },
        timesheetNotes: '',
        projectLocations: [],
        projectLocation: null,
        selectedNotes: '',
        notesSelected: '',
        valid: false,
        loading: false,
        expanded: [],
        timesheetLoading: false,
        projects: [],
        clients: [],
        users: [],
        recordToDelete: {},
        dialogDeleteRecord: false,
        loading2: false,
        copyProjects: [],
        filter: 'weekly',
        availableWeeks: Array.from({ length: 52 }, (_, i) => i + 1),
        availableMonths: moment.months(),
        monthFilter: new Date().toLocaleString('en', { month: 'long' }),
        weekFilter: 0,
        usersList: [],
        recordToEdit: {},
        dialogEditRecord: false,
        userId: JSON.parse(localStorage.getItem('userId')),
        closePayrollDialog: false,
        itemToClose: {},
        user: {},
        userType: '',
        deleteFiles: [],
    }),
    computed: {
        scopes() {
            let keys = Object.keys(this.itemsScopesTimesheet)
            const setKeys = [...new Set(keys)]
            return setKeys
        },
        allowedDates() {
            return date => {
                if (this.filter === 'weekly') {
                    return this.isDateInWeek(date)
                } else if (this.filter === 'monthly') {
                    return this.isDateInMonth(date)
                }
                return true
            }
        },
        filteredData() {
            const week = parseInt(this.weekFilter, 10)
            const year = new Date().getFullYear()
            let month = new Date(`${this.monthFilter} 1, 2000`)
            month = parseInt(month.getMonth() + 1, 10)
            if (this.filter === 'weekly' && isNaN(week)) {
                return this.usersList
            }
            if (this.filter === 'monthly' && isNaN(month)) {
                return this.usersList
            }
            let filteredUsers = []
            if (this.filter === 'weekly') {
                const weekStart = this.getWeekStartDate(year, week)
                const weekDates = this.getWeekDates(weekStart)
                let lastWeek = week - 1
                let lastYear = year
                if (week == 1) {
                    lastWeek = 52
                    lastYear = year - 1
                }
                const weekStart2 = this.getWeekStartDate(lastYear, lastWeek)
                const weekDates2 = this.getWeekDates(weekStart2)
                filteredUsers = this.usersList
                    .map(user => {
                        const filteredExternalUsersData = user.externalUsersData.filter(
                            timesheet => {
                                if (weekDates.includes(timesheet.date)) {
                                    if (this.getStatus(user) == 'Pending') {
                                        if (
                                            this.getStatusTimeSheets(
                                                timesheet
                                            ) == 'Open'
                                        ) {
                                            return true
                                        } else {
                                            return false
                                        }
                                    } else {
                                        if (
                                            this.getStatusTimeSheets(
                                                timesheet
                                            ) == 'Open'
                                        ) {
                                            return false
                                        } else {
                                            return true
                                        }
                                    }
                                } else {
                                    return false
                                }
                            }
                        )
                        const lastFilteredExternalUsersData = user.externalUsersData.filter(
                            timesheet => {
                                if (weekDates2.includes(timesheet.date)) {
                                    if (this.getStatus(user) == 'Pending') {
                                        if (
                                            this.getStatusTimeSheets(
                                                timesheet
                                            ) == 'Open'
                                        ) {
                                            return true
                                        } else {
                                            return false
                                        }
                                    } else {
                                        if (
                                            this.getStatusTimeSheets(
                                                timesheet
                                            ) == 'Closed'
                                        ) {
                                            if (
                                                timesheet.nomina &&
                                                timesheet.nomina ==
                                                    `Week ${this.weekFilter}`
                                            ) {
                                                return true
                                            } else {
                                                return false
                                            }
                                        } else {
                                            return false
                                        }
                                    }
                                } else {
                                    false
                                }
                            }
                        )
                        if (
                            filteredExternalUsersData.length > 0 ||
                            lastFilteredExternalUsersData.length > 0
                        ) {
                            return {
                                ...user,
                                externalUsersData: filteredExternalUsersData,
                                lastExternalUsersData: lastFilteredExternalUsersData,
                            }
                        }
                        return null
                    })
                    .filter(user => user !== null)
            } else {
                let lastMonth = month - 1
                let lastYear = year
                if (month == 1) {
                    lastMonth = 12
                    lastYear = year - 1
                }
                filteredUsers = this.usersList
                    .map(user => {
                        const filteredExternalUsersData = user.externalUsersData.filter(
                            timesheet => {
                                const timesheetDate = new Date(
                                    timesheet.date + 'T00:00:00'
                                )
                                const timesheetMonth =
                                    timesheetDate.getUTCMonth() + 1
                                if (timesheetMonth === month) {
                                    if (this.getStatus(user) == 'Pending') {
                                        if (
                                            this.getStatusTimeSheets(
                                                timesheet
                                            ) == 'Open'
                                        ) {
                                            return true
                                        } else {
                                            return false
                                        }
                                    } else {
                                        if (
                                            this.getStatusTimeSheets(
                                                timesheet
                                            ) == 'Open'
                                        ) {
                                            return false
                                        } else {
                                            return true
                                        }
                                    }
                                } else {
                                    return false
                                }
                            }
                        )
                        const lastFilteredExternalUsersData = user.externalUsersData.filter(
                            timesheet => {
                                const timesheetDate = new Date(
                                    timesheet.date + 'T00:00:00'
                                )
                                let timesheetMonth =
                                    timesheetDate.getUTCMonth() + 1
                                const timesheetYear = new Date(
                                    timesheet.date
                                ).getFullYear()
                                if (
                                    timesheetMonth === lastMonth &&
                                    timesheetYear == lastYear
                                ) {
                                    if (this.getStatus(user) == 'Pending') {
                                        if (
                                            this.getStatusTimeSheets(
                                                timesheet
                                            ) == 'Open'
                                        ) {
                                            return true
                                        } else {
                                            return false
                                        }
                                    } else {
                                        if (
                                            this.getStatusTimeSheets(
                                                timesheet
                                            ) == 'Closed'
                                        ) {
                                            if (
                                                timesheet.nomina &&
                                                timesheet.nomina ==
                                                    this.monthFilter
                                            ) {
                                                return true
                                            } else {
                                                return false
                                            }
                                        } else {
                                            return false
                                        }
                                    }
                                } else {
                                    return false
                                }
                            }
                        )

                        if (
                            filteredExternalUsersData.length > 0 ||
                            lastFilteredExternalUsersData.length > 0
                        ) {
                            return {
                                ...user,
                                externalUsersData: filteredExternalUsersData,
                                lastExternalUsersData: lastFilteredExternalUsersData,
                            }
                        }
                        return null
                    })
                    .filter(user => user !== null)
            }
            return filteredUsers.filter(user => {
                const status = this.getStatus(user)
                let reportName = this.monthFilter
                if (this.filter == 'weekly') {
                    reportName = `Week ${this.weekFilter}`
                }

                if (
                    status == 'Pending' &&
                    this.userType == 'superintendent' &&
                    user.nomina &&
                    user.nomina[reportName] &&
                    user.nomina[reportName].superApproved
                ) {
                    return false
                }

                if (
                    status == 'Pending' &&
                    this.userType == 'PM' &&
                    user.nomina &&
                    user.nomina[reportName] &&
                    user.nomina[reportName].superApproved
                ) {
                    return true
                }
                if (
                    ['Pending', 'Processed'].includes(status) &&
                    this.userType == 'superintendent'
                ) {
                    return true
                }
                if (status == 'Processed' && this.userType == 'PM') {
                    return true
                }
                if (
                    ['Payroll', 'Processed'].includes(status) &&
                    this.userType === 'payrollManager'
                ) {
                    return true
                }

                return false
            })
        },
        isInvalidTime() {
            const defaultTime = { hh: '', mm: '', ss: '', a: '' }

            const isValidTime = time => {
                return (
                    Number.isInteger(parseInt(time.hh)) &&
                    Number.isInteger(parseInt(time.mm)) &&
                    Number.isInteger(parseInt(time.ss)) &&
                    (time.a === 'am' || time.a === 'pm')
                )
            }
            if (!isValidTime(this.clockInSelected)) {
                return true
            }
            if (
                JSON.stringify(this.clockOutSelected) ===
                JSON.stringify(defaultTime)
            ) {
                return false
            }
            if (!isValidTime(this.clockOutSelected)) {
                return true
            }
            const convertTo24Hour = ({ hh, mm, ss, a }) => {
                let hours = parseInt(hh)
                if (a === 'pm' && hours !== 12) hours += 12
                if (a === 'am' && hours === 12) hours = 0
                return hours * 3600 + parseInt(mm) * 60 + parseInt(ss)
            }
            const clockInSeconds = convertTo24Hour(this.clockInSelected)
            const clockOutSeconds = convertTo24Hour(this.clockOutSelected)
            return clockOutSeconds <= clockInSeconds
        },
        isInvalidTimesheet() {
            const defaultTime = { hh: '', mm: '', ss: '', a: '' }

            const isValidTime = time => {
                return (
                    Number.isInteger(parseInt(time.hh)) &&
                    Number.isInteger(parseInt(time.mm)) &&
                    Number.isInteger(parseInt(time.ss)) &&
                    (time.a === 'am' || time.a === 'pm')
                )
            }
            if (
                !isValidTime(this.clockInTimesheet) ||
                !isValidTime(this.clockOutTimesheet)
            ) {
                return true
            }
            if (
                JSON.stringify(this.clockOutTimesheet) ===
                JSON.stringify(defaultTime)
            ) {
                return false
            }
            if (!isValidTime(this.clockOutTimesheet)) {
                return true
            }
            const convertTo24Hour = ({ hh, mm, ss, a }) => {
                let hours = parseInt(hh)
                if (a === 'pm' && hours !== 12) hours += 12
                if (a === 'am' && hours === 12) hours = 0
                return hours * 3600 + parseInt(mm) * 60 + parseInt(ss)
            }
            const clockInSeconds = convertTo24Hour(this.clockInTimesheet)
            const clockOutSeconds = convertTo24Hour(this.clockOutTimesheet)
            return clockOutSeconds <= clockInSeconds
        },
        keys() {
            let keys = Object.keys(this.itemsScopeSelected)
            if (this.recordToEdit.selectedCost) {
                keys = [this.recordToEdit.selectedCost.cost.reference, ...keys]
            }
            const setKeys = [...new Set(keys)]
            return setKeys
        },
        getTotalCosts() {
            return (
                (Number(this.reportData.totalHours) || 0) *
                    (Number(this.reportData.hourlyPrice) || 0) +
                (Number(this.reportData.otherCosts) || 0)
            ).toFixed(2)
        },
        validForm() {
            let otherCostValidate = false
            if (this.reportData.otherCosts != '') {
                if (
                    this.reportData.attachments &&
                    this.reportData.attachments.length > 0
                ) {
                    otherCostValidate = true
                } else {
                    otherCostValidate = false
                }
            } else {
                otherCostValidate = true
            }
            return this.reportData.hourlyPrice != '' && otherCostValidate
        },
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            if (user.permissions.includes('superintendent')) {
                this.projects = await API.getProjectsBySuperintendent()
                this.userType = 'superintendent'
            } else if (user.permissions.includes('payrollManager')) {
                this.projects = await API.getProjectsByPayrollManager()
                this.userType = 'payrollManager'
            } else {
                this.projects = await API.getProjectsByPM()
                this.userType = 'PM'
            }
            this.weekFilter = this.getCurrentWeek()
            this.projects = this.projects.filter(
                project => 'externalUsers' in project
            )
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            const {
                data: { clients },
            } = await API.getLiteClients()
            this.clients = clients
            await this.loadData()
            await this.getUserList()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        getClientName(id) {
            if (this.clients.length > 0) {
                return this.clients.find(client => client.id == id)?.name
            } else {
                return ''
            }
        },
        getUserName(id) {
            if (this.users.length > 0) {
                return this.users.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
        async loadData() {
            for (let project of this.projects) {
                project.clientName = this.getClientName(project.client)
                project.accountManagerName = this.getUserName(
                    project.accountManager
                )
                project.projectManagerName = this.getUserName(
                    project.projectManager
                )
                project.dataCollaborators = project.collaborators.map(
                    collaborator => {
                        return this.getUserName(collaborator)
                    }
                )
            }
            for (let project of this.projects) {
                project.externalUsers = await API.getExternalUsers({
                    projectId: project.id,
                })
                project.payrollReports = await API.getPayrolls({
                    projectId: project.id,
                })
            }
            this.projects = this.projects
                .map(item => {
                    item.externalUsersData = []
                    item.externalUsers.forEach(user => {
                        const userTimesheets = []
                        Object.values(user.timesheet).forEach(value => {
                            value.forEach(timesheet => {
                                userTimesheets.push({
                                    name: user.name,
                                    externalUserId: user.id,
                                    role: user.role,
                                    ...timesheet,
                                })
                            })
                        })
                        item.externalUsersData.push(userTimesheets)
                    })
                    return item
                })
                .filter(item =>
                    item.externalUsersData.some(arr => arr.length > 0)
                )
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD hh:mm A')
        },
        getCurrentWeek() {
            const now = new Date()
            const startOfYear = new Date(now.getFullYear(), 0, 1)
            const pastDays = (now - startOfYear) / 86400000
            return Math.ceil((pastDays + startOfYear.getDay() + 1) / 7)
        },
        getWeekStartDate(year, week) {
            const firstDayOfYear = new Date(year, 0, 1)
            const daysOffset = (week - 1) * 7 - firstDayOfYear.getDay()
            return new Date(year, 0, 1 + daysOffset)
        },
        getWeekDates(startDate) {
            return Array.from({ length: 7 }, (_, i) => {
                const date = new Date(startDate)
                date.setDate(startDate.getDate() + i)
                return date.toISOString().split('T')[0]
            })
        },
        async getUserList() {
            const usersList = this.projects.flatMap(
                obj => obj.externalUsersData
            )

            this.usersList = usersList.map(list => {
                const projectId = list[0].projectId
                const project = this.projects.find(
                    proj => proj.id === projectId
                )
                let user = {}
                if (project != undefined) {
                    user = project.externalUsers.find(
                        userdata => userdata.id === list[0].externalUserId
                    )
                }
                return {
                    name: list[0].name,
                    projectId: projectId,
                    projectName: project ? project.name : null,
                    externalUsersData: list,
                    ...user,
                }
            })
            this.usersList.forEach(user => {
                const projectData = this.projects.find(
                    project => project.id === user.projectId
                )
                if (projectData.payrollReports.length > 0) {
                    let payroll = projectData.payrollReports.find(
                        obj => obj.id === user.id
                    )
                    if (payroll) {
                        const { id, ...data } = payroll
                        if (user.id === id) {
                            user.payrolls = data
                        }
                    }
                }
            })
        },
        async openEditRecordDialog(item) {
            try {
                this.loading2 = true
                this.dialogEditRecord = true
                this.recordToEdit = item
                const project = this.projects.find(
                    obj => obj.id === item.projectId
                )
                this.scopeSelected = item.selectedCost?.cost.reference || null
                if (project != undefined) {
                    for (const quote of project.quotes) {
                        const response = await API.getCosts(quote.split('/')[0])
                        response.forEach(item => {
                            this.$set(this.itemsScopeSelected, item.reference, {
                                cost: {
                                    id: item.id,
                                    reference: item.reference,
                                    selected: true,
                                },
                                quote: {
                                    id: quote.split('/')[0],
                                    name: project.name,
                                },
                            })
                        })
                    }
                }
                if (item.selectedCost) {
                    this.$set(
                        this.itemsScopeSelected,
                        item.selectedCost.cost.reference,
                        item.selectedCost
                    )
                    this.scopeValue = this.itemsScopeSelected[
                        item.selectedCost.cost.reference
                    ]
                }
                if (item.clockIn) {
                    this.clockInSelected = this.convertTimestampToTime(
                        item.clockIn
                    )
                } else {
                    this.clockInSelected = { hh: '', mm: '', ss: '', a: '' }
                }
                if (item.clockOut) {
                    this.clockOutSelected = this.convertTimestampToTime(
                        item.clockOut
                    )
                } else {
                    this.clockOutSelected = { hh: '', mm: '', ss: '', a: '' }
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.loading2 = false
            }
        },
        closeDialogEditRecord() {
            this.recordToEdit = {}
            this.clockInSelected = { hh: '', mm: '', ss: '', a: '' }
            this.clockOutSelected = { hh: '', mm: '', ss: '', a: '' }
            this.scopeSelected = null
            this.itemsScopeSelected = {}
            this.scopeValue = null
            this.notesSelected = ''
            this.dialogEditRecord = false
        },
        async openReportDialog(item) {
            try {
                this.loading2 = true
                this.reportUser = _.cloneDeep(item)
                let reportName = this.monthFilter
                if (this.filter == 'weekly') {
                    reportName = `Week ${this.weekFilter}`
                }
                if (item.payrolls && item.payrolls[reportName]) {
                    this.reportData = _.cloneDeep(item.payrolls[reportName])
                    this.originalReport = _.cloneDeep(this.reportData)
                } else {
                    this.originalReport = {}
                    this.reportData.name = item.name
                    this.reportData.role = item.role
                    this.reportData.email = item.email
                    this.reportData.phone = item.phone
                    this.reportData.hourlyPrice = item.hourlyPrice
                        ? item.hourlyPrice.replace(',', '.')
                        : ''
                    this.reportData.projectName = item.projectName
                    this.reportData.externalUsersData = await this.getTimeSheetsInDate()
                    if (!this.reportData.externalUsersData.length) {
                        this.closeDialogReport()
                        return
                    }
                    this.reportData.date = this.getDateRange(
                        this.reportData.externalUsersData
                    )
                    this.reportData.totalHours = this.getTotalHours(
                        this.reportData
                    )
                }
                this.reportData.createdBy = await this.getUserName(this.userId)

                if (
                    this.reportData.attachments &&
                    this.reportData.attachments.length > 0
                ) {
                    let attachments = []
                    for (const doc of this.reportData.attachments) {
                        const storageRef = storage().ref(
                            `${this.companyId}/projects/${this.reportUser.projectId}/payrolls`
                        )
                        if (doc.newFile || doc.url) {
                            attachments.push(doc)
                            continue
                        }
                        const documentRef = storageRef.child(doc)
                        await documentRef
                            .getDownloadURL()
                            .then(response => {
                                attachments.push({
                                    name: doc,
                                    url: response,
                                })
                            })
                            .catch(() => {
                                attachments.push({
                                    name: doc,
                                    url: '',
                                })
                            })
                    }
                    for (const file of attachments) {
                        if (file.name.includes('.pdf')) {
                            file.pdfPreview = await loadPDFPreview(file.url)
                        }
                    }
                    this.$set(this.reportData, 'attachments', attachments)
                }
                this.dialogReport = true
            } catch (error) {
                console.error(error)
            } finally {
                this.loading2 = false
            }
        },
        async saveReport(finished = false) {
            try {
                this.loading2 = true
                const userIndex = this.usersList.findIndex(
                    user => user.id == this.reportUser.id
                )
                let reportName = ''
                let user = {}
                if (userIndex > -1) {
                    user = this.usersList[userIndex]
                    if (!user.payrolls) {
                        this.$set(user, 'payrolls', {})
                    }
                    if (this.filter == 'weekly') {
                        reportName = `Week ${this.weekFilter}`
                    } else {
                        reportName = this.monthFilter
                    }
                    this.$set(user.payrolls, reportName, this.reportData)
                    if (finished) {
                        this.$set(user.payrolls[reportName], 'finished', true)
                    }
                }
                for (const file of this.localAttachments) {
                    await saveFile(
                        file,
                        `${this.companyId}/projects/${this.reportUser.projectId}/payrolls`
                    )
                }
                if (this.deleteFiles.length > 0) {
                    for (const file of this.deleteFiles) {
                        if (file.exist) {
                            await deleteFile(
                                file.name,
                                `${this.companyId}/projects/${this.reportUser.projectId}/payrolls`
                            )
                        }
                    }
                    await API.deleteAttachmentPayroll({
                        projectId: this.reportUser.projectId,
                        externalUserId: this.reportUser.id,
                        reportName,
                        deleteFiles: this.deleteFiles.map(file => file.name),
                    })
                }
                const diff = objDiff(this.originalReport, this.reportData)
                if (diff.attachments) {
                    delete diff.attachments
                    diff.attachments = this.localAttachments.map(
                        item => item.name
                    )
                }
                await API.reportPayroll({
                    projectId: this.reportUser.projectId,
                    externalUserId: this.reportUser.id,
                    reportName,
                    ...diff,
                    ...(finished ? { finished: true } : {}),
                })
                const fullAttachments = [
                    ...this.reportData.attachments.map(item => item.name),
                ]
                this.$set(
                    user.payrolls[reportName],
                    'attachments',
                    fullAttachments
                )
                if (finished) {
                    const path = `${this.companyId}/projects/${this.reportUser.projectId}/payrolls`
                    let storageRef = storage().ref(path)
                    let attachmentsToReport = []
                    if (fullAttachments.length > 0) {
                        for (const file of fullAttachments) {
                            const attachmentRef = storageRef.child(file)
                            await attachmentRef
                                .getDownloadURL()
                                .then(URL => {
                                    attachmentsToReport.push({
                                        name: file,
                                        url: URL,
                                    })
                                })
                                .catch(error => {
                                    error & true
                                })
                        }
                    }
                    for (const attachment of attachmentsToReport) {
                        const isPDF = attachment.name.includes('.pdf')
                        if (isPDF) {
                            const dataURL = await loadPDFPreview(attachment.url)
                            const file = dataURLtoFile(dataURL, attachment.name)
                            const imageFile = new File(
                                [file],
                                `firstPage ${file.name.replace(
                                    /\.pdf$/,
                                    '.png'
                                )}`,
                                {
                                    type: file.type,
                                    lastModified: file.lastModified,
                                }
                            )
                            const imagePath = `${this.companyId}/projects/${
                                this.reportUser.projectId
                            }/payrolls/firstPage ${file.name.replace(
                                /\.pdf$/,
                                ''
                            )}`
                            const storageRef = storage().ref(imagePath)
                            await storageRef.put(imageFile)
                            attachment.firstPageURL = URL.createObjectURL(file)
                            attachment.isPDF = isPDF
                        }
                    }
                    this.reportData.attachments = attachmentsToReport
                    const htmlContent = await generateHTMLReport(
                        this.reportData,
                        false
                    )
                    const path2 = `${this.companyId}/projects/${this.reportUser.projectId}/payrolls/Payroll_${this.reportData.name}_${this.reportData.date}`
                    storageRef = storage().ref(path2)
                    await storageRef.put(htmlContent)
                    this.reportData.attachments = fullAttachments
                    await API.sendPayrollEmail({
                        subject: `Payroll ${this.reportData.name} ${this.reportData.date}`,
                        to: [this.reportData.email],
                        cc: [],
                        projectId: this.reportUser.projectId,
                        report: this.reportData,
                        reportPath: `Payroll_${this.reportData.name}_${this.reportData.date}`,
                    })
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.loading2 = false
                this.closeDialogReport()
            }
        },
        async getTimeSheetsInDate() {
            let temporalTimesheets = [
                ...this.reportUser.externalUsersData,
                ...this.reportUser.lastExternalUsersData,
            ]
            if (this.filter == 'weekly') {
                temporalTimesheets = temporalTimesheets.filter(
                    user => user.nomina === `Week ${this.weekFilter}`
                )
            } else {
                temporalTimesheets = temporalTimesheets.filter(
                    user => user.nomina === this.monthFilter
                )
            }
            return temporalTimesheets
        },
        getDateRange(dataList) {
            if (!dataList.length) return ''
            const dates = dataList.map(item => item.date).sort()

            const oldestDate = new Date(dates[0])
            const newestDate = new Date(dates[dates.length - 1])

            const options = { year: 'numeric', month: 'long', day: 'numeric' }
            const formattedOldest = oldestDate.toLocaleDateString(
                'en-US',
                options
            )
            const formattedNewest = newestDate.toLocaleDateString(
                'en-US',
                options
            )
            return `From ${formattedOldest} to ${formattedNewest}`
        },
        closeDialogReport() {
            this.dialogReport = false
            this.reportUser = {}
            this.deleteFiles = []
            this.localAttachments = []
            this.reportData = {
                totalHours: null,
                reportNotes: '',
                otherCosts: '',
                hourlyPrice: '',
                name: null,
                role: null,
                email: null,
                phone: null,
                projectName: null,
                externalUsersData: [],
                date: '',
                attachments: [],
            }
            this.originalReport = {}
        },
        convertTimestampToTime(timeObj) {
            if (!timeObj || typeof timeObj._seconds !== 'number') {
                return { hh: '', mm: '', ss: '', a: '' }
            }

            const date = new Date(timeObj._seconds * 1000)
            let hh = date.getHours()
            const mm = String(date.getMinutes()).padStart(2, '0')
            const ss = String(date.getSeconds()).padStart(2, '0')
            const a = hh >= 12 ? 'pm' : 'am'

            hh = hh % 12 || 12

            return {
                hh: String(hh).padStart(2, '0'),
                mm,
                ss,
                a,
            }
        },
        openDeleteRecordDialog(item) {
            this.recordToDelete = item
            this.dialogDeleteRecord = true
        },
        closeDialogDeleteRecord() {
            this.dialogDeleteRecord = false
            this.recordToDelete = {}
        },
        async deleteRecord() {
            try {
                this.loading2 = true
                const userRecord = this.usersList.findIndex(
                    user => user.id === this.recordToDelete.externalUserId
                )
                if (userRecord > -1) {
                    const recordIndex = this.usersList[
                        userRecord
                    ].externalUsersData.findIndex(
                        record => record.id === this.recordToDelete.id
                    )
                    if (recordIndex > -1) {
                        this.usersList[userRecord].externalUsersData.splice(
                            recordIndex,
                            1
                        )
                    }
                }
                await API.deleteTimeSheet({
                    externalUserId: this.recordToDelete.externalUserId,
                    id: this.recordToDelete.id,
                })
            } catch (error) {
                console.error(error)
            } finally {
                this.loading2 = false
                this.closeDialogDeleteRecord()
            }
        },
        async editRecord() {
            try {
                this.loading2 = true
                const defaultTime = { hh: '', mm: '', ss: '', a: '' }
                const userRecordIndex = this.usersList.findIndex(
                    user => user.id === this.recordToEdit.externalUserId
                )

                if (userRecordIndex > -1) {
                    const userRecord = this.usersList[userRecordIndex]
                    const recordIndex = userRecord.externalUsersData.findIndex(
                        record => record.id === this.recordToEdit.id
                    )

                    if (recordIndex > -1) {
                        const record = userRecord.externalUsersData[recordIndex]
                        if (record.clockIn) {
                            record.clockIn = this.convertToTimestamp(
                                record.date,
                                this.clockInSelected
                            )
                        }
                        if (record.clockOut) {
                            record.clockOut = this.convertToTimestamp(
                                record.date,
                                this.clockOutSelected
                            )
                        } else if (
                            JSON.stringify(this.clockOutSelected) !==
                            JSON.stringify(defaultTime)
                        ) {
                            record.clockOut = this.convertToTimestamp(
                                record.date,
                                this.clockOutSelected
                            )
                            record.clockOutLocation = record.clockInLocation
                        }
                        record.notes = this.selectedNotes
                        record.selectedCost = this.scopeValue
                        this.$set(
                            userRecord.externalUsersData,
                            recordIndex,
                            record
                        )
                        await API.updateTimeSheet({
                            id: record.id,
                            externalUserId: record.externalUserId,
                            ...(record.clockIn && { clockIn: record.clockIn }),
                            ...(record.clockOut && {
                                clockOut: record.clockOut,
                            }),
                            ...(record.clockInLocation && {
                                clockInLocation: record.clockInLocation,
                            }),
                            ...(record.clockOutLocation && {
                                clockOutLocation: record.clockOutLocation,
                            }),
                            ...(record.selectedCost && {
                                selectedCost: record.selectedCost,
                            }),
                            ...(record.notes && { notes: record.notes }),
                        })
                    }
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.loading2 = false
                this.closeDialogEditRecord()
            }
        },
        convertToTimestamp(dateString, time) {
            let hours = parseInt(time.hh)
            if (time.a === 'pm' && hours !== 12) {
                hours += 12
            } else if (time.a === 'am' && hours === 12) {
                hours = 0
            }
            const minutes = parseInt(time.mm)
            const seconds = parseInt(time.ss)
            const dateTime = moment(
                `${dateString} ${hours}:${minutes}:${seconds}`,
                'YYYY-MM-DD HH:mm:ss'
            )
            return {
                _seconds: dateTime.unix(),
                _nanoseconds: 0,
            }
        },
        getDayOfWeek(dateString) {
            return moment(dateString, 'YYYY-MM-DD').format('dddd')
        },
        updateValue() {
            this.scopeValue = this.itemsScopeSelected[this.scopeSelected]
        },
        getStatus(item) {
            if (item.nomina) {
                let reportName = this.monthFilter
                if (this.filter == 'weekly') {
                    reportName = `Week ${this.weekFilter}`
                    if (
                        item.nomina[reportName] &&
                        item.nomina[reportName].closed
                    ) {
                        if (
                            item.payrolls &&
                            item.payrolls[reportName] &&
                            item.payrolls[reportName].finished === true
                        ) {
                            return 'Processed'
                        }
                        return 'Payroll'
                    } else {
                        return 'Pending'
                    }
                } else {
                    if (
                        item.nomina[reportName] &&
                        item.nomina[reportName].closed
                    ) {
                        if (
                            item.payrolls &&
                            reportName in item.payrolls &&
                            item.payrolls[reportName].finished === true
                        ) {
                            return 'Processed'
                        }
                        return 'Payroll'
                    } else {
                        return 'Pending'
                    }
                }
            } else {
                return 'Pending'
            }
        },
        getStatusTimeSheets(item) {
            if (item.nomina) {
                return 'Closed'
            } else {
                return 'Open'
            }
        },
        getTotalHours(item) {
            let totalHours = 0
            item.externalUsersData.forEach(timesheet => {
                if (timesheet.clockIn && timesheet.clockOut) {
                    totalHours +=
                        (timesheet.clockOut._seconds -
                            timesheet.clockIn._seconds) /
                        3600
                }
            })
            if (item.lastExternalUsersData) {
                item.lastExternalUsersData.forEach(timesheet => {
                    if (timesheet.clockIn && timesheet.clockOut) {
                        totalHours +=
                            (timesheet.clockOut._seconds -
                                timesheet.clockIn._seconds) /
                            3600
                    }
                })
            }

            return totalHours.toFixed(2)
        },
        async openFileAttached(file) {
            try {
                this.loading2 = true
                const localFile = this.localAttachments.find(
                    f => f.name == file
                )
                const savedFile = this.reportData.attachments.find(
                    f => f.name == file
                )
                if (localFile) {
                    const url = URL.createObjectURL(localFile)
                    window.open(url, '_blank')
                } else if (savedFile) {
                    window.open(savedFile.url, '_blank')
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading2 = false
            }
        },
        async deleteFile(file) {
            try {
                this.loading2 = true
                const checkFile = this.reportData.attachments.find(
                    f => f.name == file
                )
                if (checkFile.newFile) {
                    this.localAttachments = this.localAttachments.filter(
                        f => f.name != file
                    )
                    this.reportData.attachments = this.reportData.attachments.filter(
                        f => f.name != file
                    )
                } else {
                    if (checkFile.url) {
                        this.deleteFiles.push({ name: file, exist: true })
                    } else {
                        this.deleteFiles.push({ name: file, exist: false })
                    }
                    this.reportData.attachments = this.reportData.attachments.filter(
                        f => f.name != file
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading2 = false
            }
        },
        onButtonClick() {
            this.$refs.uploader2.click()
        },
        async onFileChanged(e) {
            await this.saveFile(e.target.files[0])
        },
        async saveFile(file) {
            try {
                this.loading2 = true
                const lastDotIndex = file.name.lastIndexOf('.')
                const baseName =
                    lastDotIndex !== -1
                        ? file.name.slice(0, lastDotIndex)
                        : file.name
                const ext =
                    lastDotIndex !== -1 ? file.name.slice(lastDotIndex + 1) : ''
                let newName = file.name
                let counter = 1
                const existingNames = this.reportData.attachments
                    ? this.reportData.attachments.map(att => att.name)
                    : []

                while (existingNames.includes(newName)) {
                    newName = ext
                        ? `${baseName}(${counter}).${ext}`
                        : `${baseName}(${counter})`
                    counter++
                }
                const newFile = new File([file], newName, { type: file.type })
                this.localAttachments.push(newFile)
                if (newFile.name.includes('.pdf')) {
                    newFile.pdfPreview = await loadPDFPreview(
                        URL.createObjectURL(newFile)
                    )
                }
                if (!this.reportData.attachments) {
                    this.reportData.attachments = [
                        {
                            name: newFile.name,
                            url: URL.createObjectURL(newFile),
                            ...(newFile.pdfPreview
                                ? { pdfPreview: newFile.pdfPreview }
                                : {}),
                            newFile: true,
                        },
                    ]
                } else {
                    this.reportData.attachments.push({
                        name: newFile.name,
                        url: URL.createObjectURL(newFile),
                        ...(newFile.pdfPreview
                            ? { pdfPreview: newFile.pdfPreview }
                            : {}),
                        newFile: true,
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading2 = false
            }
        },
        formatTime(time) {
            return moment.unix(time._seconds).format('hh:mm A')
        },
        getUsersWithoutNomina(data) {
            if (
                !data ||
                !data.lastExternalUsersData ||
                !data.externalUsersData
            ) {
                throw new Error(
                    'The input object does not have the expected format.'
                )
            }
            const allUsers = [
                ...data.lastExternalUsersData,
                ...data.externalUsersData,
            ]
            const usersWithoutNomina = allUsers.filter(
                user => !Object.prototype.hasOwnProperty.call(user, 'nomina')
            )
            const uniqueIds = [
                ...new Set(usersWithoutNomina.map(user => user.id)),
            ]
            return uniqueIds
        },
        async closePayroll() {
            try {
                this.loading2 = true
                const uniqueIds = this.getUsersWithoutNomina(this.itemToClose)
                const userIndex = this.usersList.findIndex(
                    user => user.id == this.itemToClose.id
                )
                let reportName = this.monthFilter
                if (this.filter == 'weekly') {
                    reportName = `Week ${this.weekFilter}`
                }
                if (this.userType == 'superintendent') {
                    if (userIndex > -1) {
                        const user = this.usersList[userIndex]
                        if (!user.nomina) {
                            this.$set(user, 'nomina', {})
                        }
                        this.$set(user.nomina, reportName, {
                            superApproved: true,
                        })
                    }
                    await API.assignPayrollProcess({
                        externalUserId: this.itemToClose.id,
                        timesheets: uniqueIds,
                        reportName: { nomina: reportName },
                        type: 'superintendent',
                    })
                } else {
                    if (userIndex > -1) {
                        const user = this.usersList[userIndex]
                        this.$set(user.nomina, reportName, {
                            closed: true,
                            ...user.nomina[reportName],
                        })
                        user.externalUsersData.forEach(timesheet => {
                            if (uniqueIds.includes(timesheet.id)) {
                                timesheet.nomina = reportName
                            }
                        })
                    }
                    await API.assignPayrollProcess({
                        externalUserId: this.itemToClose.id,
                        timesheets: uniqueIds,
                        reportName: { nomina: reportName },
                        type: 'PM',
                    })
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.loading2 = false
                this.closeDialogPayroll()
            }
        },
        openAlertPayroll(item) {
            this.itemToClose = _.cloneDeep(item)
            this.closePayrollDialog = true
        },
        closeDialogPayroll() {
            this.closePayrollDialog = false
            this.itemToClose = {}
        },
        async openReport(item) {
            try {
                this.loading = false
                let reportName = ''
                if (this.filter == 'weekly') {
                    reportName = `Week ${this.weekFilter}`
                } else {
                    reportName = this.monthFilter
                }
                if (
                    item.payrolls[reportName] &&
                    item.payrolls[reportName].attachments &&
                    item.payrolls[reportName].attachments.length > 0
                ) {
                    let attachments = []
                    for (const doc of item.payrolls[reportName].attachments) {
                        const storageRef = storage().ref(
                            `${this.companyId}/projects/${item.projectId}/payrolls`
                        )
                        if (doc.newFile || doc.url) {
                            attachments.push(doc)
                            continue
                        }
                        const documentRef = storageRef.child(doc)
                        await documentRef
                            .getDownloadURL()
                            .then(response => {
                                attachments.push({
                                    name: doc,
                                    url: response,
                                })
                            })
                            .catch(() => {
                                attachments.push({
                                    name: doc,
                                    url: '',
                                })
                            })
                    }
                    for (const file of attachments) {
                        if (file.name.includes('.pdf')) {
                            file.pdfPreview = await loadPDFPreview(file.url)
                        }
                    }
                    item.payrolls[reportName].attachments = attachments
                }
                for (const attachment of item.payrolls[reportName]
                    .attachments) {
                    const isPDF = attachment.name.includes('.pdf')
                    if (isPDF) {
                        const dataURL = await loadPDFPreview(attachment.url)
                        const file = dataURLtoFile(dataURL, attachment.name)
                        attachment.firstPageURL = URL.createObjectURL(file)
                        attachment.isPDF = isPDF
                    }
                }
                await generateHTMLReport(item.payrolls[reportName])
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        },
        getTimesheetHeaders(timesheet) {
            let timesheetHeaders = [
                {
                    text: 'Day',
                    value: 'date',
                    align: 'left',
                    sortable: false,
                    width: '130',
                },
                {
                    text: 'Role',
                    value: 'role',
                    align: 'center',
                    sortable: false,
                    width: '135',
                },
                {
                    text: 'Scope',
                    value: 'scope',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Project Location',
                    value: 'location',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Clock In',
                    value: 'clockIn',
                    align: 'center',
                    sortable: false,
                    width: '105',
                },
                {
                    text: 'Clock Out',
                    value: 'clockOut',
                    align: 'center',
                    sortable: false,
                    width: '105',
                },
                {
                    text: 'Total time',
                    value: 'totalTime',
                    align: 'center',
                    sortable: false,
                    width: '120',
                },
                {
                    text: 'Status',
                    value: 'status',
                    align: 'center',
                    sortable: false,
                    width: '120',
                },
                {
                    text: 'Notes',
                    value: 'notes',
                    align: 'center',
                    sortable: false,
                },
            ]
            if (this.getStatus(timesheet) == 'Pending') {
                timesheetHeaders.push({
                    text: 'Actions',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                    width: '90',
                })
            }
            return timesheetHeaders
        },
        async createTimesheet(timesheet) {
            try {
                this.loading2 = true
                this.dialogCreateTimesheet = true
                this.selectedTimesheet = _.cloneDeep(timesheet)
                const project = this.projects.find(
                    obj => obj.id === timesheet.projectId
                )
                if (project != undefined) {
                    this.projectLocations = await API.getProjectLocations({
                        projectId: timesheet.projectId,
                    })
                    for (const quote of project.quotes) {
                        const response = await API.getCosts(quote.split('/')[0])
                        response.forEach(item => {
                            this.$set(
                                this.itemsScopesTimesheet,
                                item.reference,
                                {
                                    cost: {
                                        id: item.id,
                                        reference: item.reference,
                                        selected: true,
                                    },
                                    quote: {
                                        id: quote.split('/')[0],
                                        name: project.name,
                                    },
                                }
                            )
                        })
                    }
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.loading2 = false
            }
        },
        closeCreateTimesheet() {
            this.selectedTimesheet = {}
            this.dialogCreateTimesheet = false
            this.scopeTimesheet = null
            this.itemsScopesTimesheet = {}
            this.scopeValueTimesheet = null
            this.selectedDateTimesheet = null
            this.timesheetDate = null
            this.valid3 = false
            this.clockInTimesheet = { hh: '', mm: '', ss: '', a: '' }
            this.clockOutTimesheet = { hh: '', mm: '', ss: '', a: '' }
            this.timesheetNotes = ''
            this.projectLocations = []
            this.projectLocation = null
        },
        async saveTimesheet() {
            try {
                this.loading2 = true
                const locationInfo = this.projectLocations.find(
                    location => (location.name = this.projectLocation)
                )
                if (!locationInfo) {
                    return null
                }
                const timesheet = {
                    externalUserId: this.selectedTimesheet.id,
                    clockIn: this.convertToTimestamp(
                        this.timesheetDate,
                        this.clockInTimesheet
                    ),
                    clockInLocation: locationInfo.location,
                    clockOut: this.convertToTimestamp(
                        this.timesheetDate,
                        this.clockOutTimesheet
                    ),
                    clockOutLocation: locationInfo.location,
                    date: String(this.timesheetDate),
                    projectId: this.selectedTimesheet.projectId,
                    projectLocation: locationInfo,
                    selectedCost: this.scopeValueTimesheet,
                    ...(this.timesheetNotes != ''
                        ? { notes: this.timesheetNotes }
                        : {}),
                }
                const response = await API.createTimesheet(timesheet)
                if (response) {
                    const userRecordIndex = this.usersList.findIndex(
                        user => user.id === this.selectedTimesheet.id
                    )
                    if (userRecordIndex > -1) {
                        const userRecord = this.usersList[userRecordIndex]
                        this.$set(
                            userRecord.externalUsersData,
                            userRecord.externalUsersData.length,
                            {
                                role: this.selectedTimesheet.role,
                                externalUserId: this.selectedTimesheet.id,
                                name: this.selectedTimesheet.name,
                                ...response,
                            }
                        )
                        this.usersList = [...this.usersList]
                    }
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.loading2 = false
                this.closeCreateTimesheet()
            }
        },
        isDateInWeek(date) {
            const selectedWeek = this.weekFilter
            const weekOfDate = this.getWeekNumberSundayStart(date)
            return selectedWeek === weekOfDate
        },
        isDateInMonth(date) {
            const selectedMonth = this.monthFilter.toLowerCase()
            const d = new Date(date + 'T00:00:00')
            const monthOfDate = d
                .toLocaleString('en-US', { month: 'long' })
                .toLowerCase()
            return selectedMonth === monthOfDate
        },
        getWeekNumberSundayStart(date) {
            const d = new Date(date + 'T00:00:00')
            d.setHours(0, 0, 0, 0)
            const yearStart = new Date(d.getFullYear(), 0, 1)
            const firstDayOfYear = yearStart.getDay()
            const diffDays = Math.floor((d - yearStart) / 86400000)
            return Math.floor((diffDays + firstDayOfYear) / 7) + 1
        },
        updateDate(date) {
            this.timesheetDate = date
            this.menu = false
        },
        updateScope() {
            this.scopeValueTimesheet = this.itemsScopesTimesheet[
                this.scopeTimesheet
            ]
        },
        setHourlyPrice(item) {
            const totalHours = this.getTotalHours(item)
            let totalPrice = 0
            if (
                item.payrolls?.[
                    this.filter === 'weekly'
                        ? `Week ${this.weekFilter}`
                        : this.monthFilter
                ]?.hourlyPrice
            ) {
                const hourlyPrice = Number(
                    item.payrolls[
                        this.filter === 'weekly'
                            ? `Week ${this.weekFilter}`
                            : this.monthFilter
                    ].hourlyPrice
                )
                if (!isNaN(hourlyPrice) && hourlyPrice != 0) {
                    totalPrice = (hourlyPrice * totalHours).toFixed(2)
                } else {
                    totalPrice = '-'
                }
            } else if (item.hourlyPrice) {
                if (
                    !isNaN(Number(item.hourlyPrice)) &&
                    Number(item.hourlyPrice) != 0
                ) {
                    totalPrice = (
                        Number(item.hourlyPrice) * totalHours
                    ).toFixed(2)
                } else {
                    totalPrice = '-'
                }
            } else {
                totalPrice = '-'
            }
            return totalPrice
        },
        setOtherCosts(item) {
            let total = 0
            if (
                item.payrolls?.[
                    this.filter === 'weekly'
                        ? `Week ${this.weekFilter}`
                        : this.monthFilter
                ]?.otherCosts
            ) {
                const otherCosts = Number(
                    item.payrolls[
                        this.filter === 'weekly'
                            ? `Week ${this.weekFilter}`
                            : this.monthFilter
                    ].otherCosts
                )
                if (!isNaN(otherCosts) && otherCosts != 0) {
                    total = otherCosts.toFixed(2)
                } else {
                    total = '-'
                }
            } else {
                total = '-'
            }
            return total
        },
        setTotal(item) {
            const hourlyPrice = this.setHourlyPrice(item)
            const otherCosts = this.setOtherCosts(item)
            let total = 0
            if (hourlyPrice != '-' || otherCosts != '-') {
                if (hourlyPrice != '-') {
                    if (otherCosts != '-') {
                        total = (
                            Number(hourlyPrice) + Number(otherCosts)
                        ).toFixed(2)
                    } else {
                        total = Number(hourlyPrice).toFixed(2)
                    }
                } else {
                    if (otherCosts != '-') {
                        total = Number(otherCosts).toFixed(2)
                    }
                }
            } else {
                total = '-'
            }
            return total
        },
    },
}
</script>
<style>
.v-menu__content {
    z-index: 10000 !important;
}
.v-data-table__wrapper {
    position: relative;
    z-index: 2;
}
.custom-dialog {
    overflow: visible !important;
}
.preview_pdf {
    display: inline-block;
    max-height: 150px;
    max-width: 150px;
}
.custom-timepicker .active {
    background-color: #1976d2 !important;
    color: white !important;
}
</style>
