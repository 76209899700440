const moment = require('moment')
const html2pdf = require('html2pdf.js')
const script = document.createElement('script')
script.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.min.js'
script.onload = () => {
    window.pdfjsLib.GlobalWorkerOptions.workerSrc =
        'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js'
}
document.head.appendChild(script)

export const generateHTMLReport = async (reportData, open = true) => {
    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
            body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
            }
            .container {
                width: 100%;
                max-width: 900px;
                margin: auto;
                padding: 5px 20px;
            }
            h2 {
                color: #333;
                font-size: 18px;
                margin-bottom: 10px;
            }
            .divider {
                border-top: 1px solid #ddd;
                margin: 10px 0;
            }
            .row {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 20px;
            }
            .col {
                padding: 10px;
            }
            .row1 {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 5px;
            }
            .col1 {
                padding: 5px;
            }
            .checkbox-label {
                display: flex;
                align-items: center;
            }
            table {
                width: 100%;
                page-break-inside: auto;
                border-collapse: collapse;
            }
            th, td {
                border-bottom: 1px solid rgb(201, 201, 201);
                padding: 8px;
                text-align: left;
                word-wrap: break-word;
            }
            tr {
                page-break-inside: avoid !important; /* Evita que las filas se corten a la mitad */
                break-inside: avoid;
            }
            .attachment {
                font-size: 12px;
                border-radius: 20px;
                border: none;
                padding: 5px 10px;
                background-color: transparent;
                color: #1f6bb5;
                font-weight: bold;
                transition: transform 0.1s ease;
            }
            .attachment:hover {
                cursor: pointer;
            }
            .attachment:active {
                transform: scale(0.95);
                background-color: #1f6bb5;
            }
            .avoid-break {
                page-break-inside: avoid;
                break-inside: avoid;
            }
            .page-break-before {
                page-break-before: always;
                break-before: always;
            }
            .page-break-after {
                page-break-after: always;
                break-after: always;
            }
        </style>
    </head>
    <body style="margin: 20px 0px;">
        <div class="container">
            <div class="row">
                <div class="col" style="flex: 2; padding-bottom: 0px;">
                    <h5 style="font-weight: 100; margin-bottom: -10px; margin-top: 0px;">
                        Payroll Report
                    </h5>
                    <h2 style="margin-top: 15px; margin-bottom: 0px;">${
                        reportData.date
                    }</h2>
                </div>
                <div class="col" style="flex: 2; text-align: right; padding-bottom: 0px;">
                ${
                    open
                        ? '<button onclick="imprimirPagina()" class="attachment">Download</button>'
                        : ''
                } 
                    <h2>${reportData.name}</h2>
                </div>
            </div>
            <div class="divider"></div>
        </div>

        <div class="container" style="margin-top: 20px;">
            <h2>DETAILS</h2>
            <div class="divider"></div>
            
            ${generateDetails(reportData)}
        </div>
        
        ${
            reportData.externalUsersData &&
            reportData.externalUsersData.length > 0
                ? `
            <div class="container">
                <h2>WORK LOG</h2>
                <div class="divider"></div>
                <table>
                    ${generateWorkLogTable(reportData.externalUsersData || [])}
                </table>
            </div>`
                : ''
        }
        
        ${
            reportData.attachments && reportData.attachments.length > 0
                ? `
            <div class="container avoid-break" style="margin-top: 20px;">
                <h2>ATTACHMENTS</h2>
                <div class="divider"></div>
                <div style="display: flex; flex-wrap: wrap; row-gap: 10px; column-gap: 15px;">
                    ${generateAttachemntItems(reportData.attachments)}
                </div>
            </div>`
                : ''
        }
        
        <div class="container avoid-break" style="margin-top: 20px;">
            <h2>Created By</h2>
            <div class="divider"></div>
            <label class="checkbox-label">${reportData.createdBy || ''}</label>
        </div>
        
        <script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js"></script>
        <script>
            async function imprimirPagina() {
                const prueba = \`${htmlDownload.replace(/`/g, '\\`')}\`;
                const tempDoc = new DOMParser().parseFromString(prueba, "text/html");
                tempDoc.body.innerHTML += document.body.innerHTML
                tempDoc.querySelectorAll("button").forEach(button => button.remove());
                tempDoc.querySelectorAll("script").forEach(script => script.remove());
                const container = tempDoc.createElement('div')
                container.innerHTML = tempDoc.documentElement.outerHTML

                const options = {
                    margin: 10,
                    filename: 'payroll_${reportData.name}_${
        reportData.date
    }.pdf',
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2, useCORS: true },
                    jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' }
                };
                container.style.width = "185mm";
                await html2pdf().from(container).set(options).save();
                
            }
        </script>
    </body>
    </html>
    `
    if (!open) {
        const container = document.createElement('div')
        container.innerHTML = htmlContent
        document.body.appendChild(container)

        const options = {
            margin: 10,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
        }

        const res = await html2pdf()
            .from(container)
            .set(options)
            .outputPdf('blob')

        document.body.removeChild(container)
        return res
    } else {
        const newWindow = window.open('', '_blank')
        newWindow.document.write(htmlContent)
        newWindow.document.title = `${reportData.date} Field Report - ${reportData.name}`
        newWindow.document.close()
    }
}

const htmlDownload = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
        }
        .container {
            width: 100%;
            max-width: 900px;
            margin: auto;
            padding: 5px 20px;
        }
        h2 {
            color: #333;
            font-size: 18px;
            margin-bottom: 10px;
        }
        .divider {
            border-top: 1px solid #ddd;
            margin: 10px 0;
        }
        .row {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
        }
        .col {
            padding: 10px;
        }
        .checkbox-label {
            display: flex;
            align-items: center;
        }
        table {
            width: 100%;
            page-break-inside: auto;
            border-collapse: collapse;
        }
        th, td {
            border-bottom: 1px solid rgb(201, 201, 201);
            padding: 8px;
            text-align: left;
            word-wrap: break-word;
        }
        tr {
            page-break-inside: avoid !important; /* Evita que las filas se corten a la mitad */
            break-inside: avoid;
        }
        .attachment{
            font-size: 12px;
            border-radius: 20px;
            border: none;
            padding: 5px 10px;
            background-color: transparent;
            color: #1f6bb5;
            font-weight: bold;
            transition: transform 0.1s ease;
        }
        .attachment:hover{
            cursor: pointer;
        }
        .attachment:active {
            transform: scale(0.95);
            background-color: #1f6bb5;
        }
        .avoid-break {
            page-break-inside: avoid;
            break-inside: avoid;
        }
        .page-break-before {
            page-break-before: always;
            break-before: always;
        }
        .page-break-after {
            page-break-after: always;
            break-after: always;
        }
    </style>
</head>
<body style="margin: 20px 0px;">
</body>
</html>
`

const generateDetails = reportData => {
    const fields = [
        { label: 'Role', value: reportData.role },
        { label: 'Email', value: reportData.email },
        { label: 'Phone', value: reportData.phone },
        { label: 'Project', value: reportData.projectName },
        { label: 'Reported Hours', value: reportData.totalHours },
        { label: 'Hourly Price', value: '$ ' + reportData.hourlyPrice },
        { label: 'Other Costs', value: '$ ' + reportData.otherCosts },
        {
            label: 'Total',
            value:
                '$ ' +
                (
                    Number(reportData.otherCosts) +
                    Number(reportData.totalHours) *
                        Number(reportData.hourlyPrice)
                ).toFixed(2),
        },
        { label: 'Notes', value: reportData.reportNotes },
    ]
    return fields
        .map(
            field => `
        <div class="row1">
            <div class="col1" style="flex: 1; font-weight: bold;">
                <label>${field.label}:</label>
            </div>
            <div class="col1" style="flex: 3;">
                <label>${field.value || '-'}</label>
            </div>
        </div>`
        )
        .join('')
}

const generateWorkLogTable = data => {
    let headers = ''
    let rows = ''

    const perUserHeaders = [
        { key: 'date', label: 'Day' },
        { key: 'role', label: 'Role' },
        { key: 'scope', label: 'Scope' },
        { key: 'location', label: 'Location' },
        { key: 'clockIn', label: 'Clock In' },
        { key: 'clockOut', label: 'Clock Out' },
        { key: 'hours', label: 'Total Hours' },
        { key: 'notes', label: 'Notes' },
    ]
    //rowsText

    data.forEach(entry => {
        rows += '<tr>'
        perUserHeaders.forEach(header => {
            if (header.key == 'date') {
                rows += `<td style="text-align: center">${entry.date}</td>`
            } else if (header.key == 'role') {
                rows += `<td style="text-align: center">${entry.role}</td>`
            } else if (header.key == 'scope') {
                rows += `<td style="text-align: center">${
                    entry.selectedCost ? entry.selectedCost.cost.reference : '-'
                }</td>`
            } else if (header.key == 'location') {
                rows += `<td style="text-align: center">${entry.projectLocation.name}</td>`
            } else if (header.key == 'clockIn') {
                rows += `<td style="text-align: center">${
                    entry.clockIn ? formatTime(entry.clockIn) : '-'
                }</td>`
            } else if (header.key == 'clockOut') {
                rows += `<td style="text-align: center">${
                    entry.clockOut ? formatTime(entry.clockOut) : '-'
                }</td>`
            } else if (header.key == 'hours') {
                rows += `<td style="text-align: center">${
                    entry.clockIn && entry.clockOut
                        ? (
                              (entry.clockOut._seconds -
                                  entry.clockIn._seconds) /
                              3600
                          ).toFixed(2)
                        : '-'
                }</td>`
            } else if (header.key == 'notes') {
                rows += `<td style="text-align: center">${entry.notes ||
                    '-'}</td>`
            }
        })
        rows += '</tr>'
    })

    //headersText
    headers = perUserHeaders
        .map(header => `<th style="text-align: center">${header.label}</th>`)
        .join('')
    return headers + rows
}

const formatTime = time => {
    return moment.unix(time._seconds).format('hh:mm A')
}

const generateAttachemntItems = files => {
    let filesString = ''
    for (const file of files) {
        filesString += `<a href="${file.url}" target="_blank">
            <img src="${
                file.isPDF ? file.firstPageURL : file.url
            }" width="100px" height="100px" />
        </a>`
    }
    return filesString
}

const createPdfPreview = async pdfBytes => {
    // Load pdf doc
    const loadingTask = window.pdfjsLib.getDocument({ data: pdfBytes })
    const pdf = await loadingTask.promise

    // get first page
    const page = await pdf.getPage(1)

    // config canvas
    const viewport = page.getViewport({ scale: 1.5 })
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.height = viewport.height
    canvas.width = viewport.width

    // render page in canvas
    const renderContext = {
        canvasContext: context,
        viewport: viewport,
    }

    await page.render(renderContext).promise

    // canvas to base64
    return canvas.toDataURL('image/png')
}

export const loadPDFPreview = async url => {
    const pdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const previewUrl = await createPdfPreview(pdfBytes)
    return previewUrl
}
